import Uri from 'urijs';
import BaseMerchant from './BaseMerchant';
import type { Site } from '../../types/Site';

interface Territory {
  suffix: string;
  tag: string;
}

export default class AmazonMerchant extends BaseMerchant {
  // These URLS will not get GeoRioted and instead return their original url
  standardUrls = [`gaming.amazon.com`];

  suffix: string;

  tag: string;

  tsid: string | number | null | undefined;

  trackingId?: string | number;

  targetDomain?: string;

  constructor(
    id: number,
    name: string,
    territory: string,
    tsid: string | number | null | undefined,
    url: string,
    site?: Site,
    targetDomain?: string,
    trackingId?: string | number,
  ) {
    super(id, name, url, site);
    const { suffix, tag } = this.getTerritory(territory);
    this.suffix = suffix;
    this.tag = tag;
    this.tsid = tsid;
    this.trackingId = trackingId;
    this.targetDomain = targetDomain;
    this.trackedLinkPattern = 'target.georiot.com'; // Do not touch GeoRiot links
  }

  getTerritory(territory: string): Territory | Record<string, never> {
    const territories = {
      uk: {
        suffix: '21',
        tag: 'hawk-future-21',
      },
      us: {
        suffix: '20',
        tag: 'hawk-future-20',
      },
      ca: {
        suffix: '20',
        tag: 'hawk-future-ca-20',
      },
      de: {
        suffix: '21',
        tag: 'techracom00-21',
      },
      fr: {
        suffix: '21',
        tag: 'tech0c1e-21',
      },
      ae: {
        suffix: '21',
        tag: 'hawkuae-21',
      },
      it: {
        suffix: '21',
        tag: 'tech08c20-21',
      },
      es: {
        suffix: '21',
        tag: 'tech0ae4-21',
      },
      eg: {
        suffix: '21',
        tag: 'techeg0-21',
      },
      in: {
        suffix: '21',
        tag: 'tech0e7-21',
      },
      au: {
        suffix: '22',
        tag: 'hawk-future-22',
      },
      sg: {
        suffix: '22',
        tag: 'pricepandasg-22',
      },
      sa: {
        suffix: '21',
        tag: 'hawkksa-21',
      },
      nl: {
        suffix: '21',
        tag: 'tech0e9000b-21',
      },
      se: {
        suffix: '21',
        tag: 'tech0se-21',
      },
      be: {
        suffix: '21',
        tag: 'tech0be-21',
      },
    };

    return territories[territory] || {};
  }

  rewrite(url: string): string {
    // Set the tracking code if the custom code can be found in the href
    // Get the custom-tracking string from the url but only if it is the click ref

    // Add tracking to Amazon link
    const urlObject = new Uri(url);

    // Remove tag + subtag if present
    urlObject.removeQuery('tag');
    urlObject.removeQuery('ascsubtag');

    // Set Hawk tag + subtag
    urlObject.addQuery('tag', this.tag);
    urlObject.addQuery('ascsubtag', `hawk-custom-tracking-${this.suffix}`);

    let newUrl = urlObject.toString();

    // Ensure there is no www. in the Amazon link - prevent adblocking
    newUrl = newUrl.replace('://www.', '://');

    // If TSID has been specified and the site isn't hawk, create a GeoRiot link
    if (
      this.tsid &&
      this.targetDomain &&
      this.site &&
      this.site.code !== 'hawk' &&
      !this.standardUrls.includes(urlObject.hostname())
    ) {
      newUrl = `https://target.georiot.com/Proxy.ashx?tsid=${this.tsid}&GR_URL=${encodeURIComponent(
        newUrl,
      )}`;
    }

    return newUrl;
  }

  isMatch(hostname: string, path: string): boolean {
    const isMatch = super.isMatch(hostname, path);
    // Only "direct" Amazon links rewrite via GeoRiot, not subdomains (for instance aws.amazon.com)
    // Any exceptions are specified in standardUrls where the original url + tags are returned
    // If the link contains www, it has been stripped out at the hawklinks.js level
    return isMatch && (hostname === this.hostname || this.standardUrls.includes(hostname));
  }
}
